import axios from 'axios'

let backendURL = `http://localhost:1337/`

if (process.env.NODE_ENV === 'production') {
  backendURL = `https://project-review.tredence.com/`
}

const httpRequest = axios.create({
  baseURL: backendURL,
})

// Request interceptor
httpRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(
      `msal.${process.env.REACT_APP_ID}.idtoken`
    )

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }

    config.headers['X-Frame-Options'] = 'SAMEORIGIN'

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptor
httpRequest.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    console.error(error)

    if (error.response.status === 401) {
      window.location.replace('/')
      localStorage.clear()
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default httpRequest
