let graph = require('@microsoft/microsoft-graph-client')

function getAuthenticatedClient(accessToken) {
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken)
    },
  })
  return client
}

export async function getUserData(accessToken) {
  const client = getAuthenticatedClient(accessToken)

  const user = await client
    .api('/me')
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get()
  return user
}
// use local generated access token for development --->

async function uploadFileChunks(
  client,
  driveId,
  folderId,
  filename,
  file,
  accessToken
) {
  const CHUNK_SIZE = 5 * 1024 * 1024 // 5 MB chunk size (adjust as needed)
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE)
  const uploadSession = await client
    .api(
      `/drives/${driveId}/items/${folderId}:/${filename}:/createUploadSession`
    )
    .post()

  const uploadUrl = uploadSession.uploadUrl
  const arrayBuffer = await file.arrayBuffer()

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * CHUNK_SIZE
    const end = Math.min(start + CHUNK_SIZE, file.size)
    const chunk = arrayBuffer.slice(start, end)

    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: chunk,
        headers: {
          'Content-Range': `bytes ${start}-${end - 1}/${file.size}`,
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!response.ok) {
        console.error(
          'Chunk upload error:',
          response.status,
          response.statusText
        )
      }
    } catch (error) {
      console.error('Chunk upload error:', error)
    }
  }
}

async function uploadFileChunkstoFinalSP(
  client,
  driveId,
  folderId,
  filename,
  file,
  accessToken
) {
  const CHUNK_SIZE = 5 * 1024 * 1024 // 5 MB chunk size (adjust as needed)
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE)
  const uploadSession = await client
    .api(
      `/drives/${driveId}/items/${folderId}:/${filename}:/createUploadSession`
    )
    .post()

  const uploadUrl = uploadSession.uploadUrl

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * CHUNK_SIZE
    const end = Math.min(start + CHUNK_SIZE, file.size)
    const chunk = file.buffer.slice(start, end)

    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: chunk,
        headers: {
          'Content-Range': `bytes ${start}-${end - 1}/${file.size}`,
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!response.ok) {
        console.error(
          'Chunk upload error:',
          response.status,
          response.statusText
        )
        throw new Error(
          `Chunk upload error: ${response.status} ${response.statusText}`
        )
      }
    } catch (error) {
      console.error('Chunk upload error:', error)
      throw error
    }
  }

  return filename // Return the filename as a response only if the upload was successful
}

async function findOrCreateFolder(client, driveId, parentId, folderName) {
  const escapedFolderName = folderName.replace(/['",]/g, ' ')
  try {
    const existingFolderQuery = `/drives/${driveId}/items/${parentId}/children?filter=name eq '${escapedFolderName}'`
    const existingFolderResponse = await client.api(existingFolderQuery).get()
    if (existingFolderResponse.value.length > 0) {
      return existingFolderResponse.value[0].id
    } else {
      const decodedfoldername = decodeURIComponent(folderName)
      const escapedFolderName = decodedfoldername.replace(/['",]/g, ' ')
      const newFolderResponse = await client
        .api(`/drives/${driveId}/items/${parentId}/children`)
        .post({
          name: escapedFolderName,
          folder: {},
        })
      return newFolderResponse.id
    }
  } catch (error) {
    throw error
  }
}

async function findFolder(client, driveId, parentId, folderName) {
  const escapedFolderName = folderName.replace(/['",]/g, ' ')
  try {
    const existingFolderQuery = `/drives/${driveId}/items/${parentId}/children?filter=name eq '${escapedFolderName}'`
    const existingFolderResponse = await client.api(existingFolderQuery).get()
    if (existingFolderResponse.value.length > 0) {
      return existingFolderResponse.value[0].id
    }
  } catch (error) {
    throw error
  }
}
export const uploadFileToSharePoint = async (
  file,
  vertical,
  account,
  project,
  checklist,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    // Fetch siteId, driveId, and itemId
    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/KMS.Mantle`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const itemPath = '/PR Staging Area'
    const itemIdResponse = await client
      .api(`/drives/${driveId}/root:${itemPath}`)
      .get()
    const itemId = itemIdResponse.id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())
    const originalFileName = file.name // Replace with your original file name

    const fileExtension = originalFileName.split('.').pop()
    const newFileName = `${originalFileName.replace(
      `.${fileExtension}`,
      `_${checklist}.${fileExtension}`
    )}`
    // Find or create the review folder
    const VerticalfolderId = await findOrCreateFolder(
      client,
      driveId,
      itemId,
      Verticalfolder
    )

    // Find or create the phase level child folder
    const AccountfolderId = await findOrCreateFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )

    // Find or create the checklist level child folder
    const ProjectfolderId = await findOrCreateFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )

    // Perform the file upload
    await uploadFileChunks(
      client,
      driveId,
      ProjectfolderId,
      newFileName,
      file,
      accessToken
    )

    return 'File uploaded successfully'

    /**File Upload for Direct Uplaod without chunking */
    // const uploadResult = await client
    //   .api(
    //     `/sites/${siteId}/drives/${driveId}/items/${ChecklistfolderId}:/${file.name}:/content`
    //   )
    //   .put(file)

    // return uploadResult
  } catch (error) {
    throw error.message
  }
}

export const allFilesInSharePoint = async (
  vertical,
  account,
  project,
  checklistsaved,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/KMS.Mantle`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const itemPath = '/PR Staging Area'
    const itemIdResponse = await client
      .api(`/drives/${driveId}/root:${itemPath}`)
      .get()
    const itemId = itemIdResponse.id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())

    const VerticalfolderId = await findOrCreateFolder(
      client,
      driveId,
      itemId,
      Verticalfolder
    )

    const AccountfolderId = await findOrCreateFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )
    const ProjectfolderId = await findOrCreateFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )
    const existingfilesQuery = `/drives/${driveId}/items/${ProjectfolderId}/children`

    const existingfilesResponse = await client.api(existingfilesQuery).get()

    const matchingFiles = existingfilesResponse.value.filter((file) => {
      return checklistsaved.some((checklistData) => {
        const checklistName = checklistData.checklist
        const dynamicExtension = file.name.split('.').pop()
        if (checklistData?.required === 'mandatory') {
          return file.name.endsWith(`_casestudy.${dynamicExtension}`)
        } else {
          return file.name.endsWith(`_${checklistName}.${dynamicExtension}`)
        }
      })
    })

    const filesList = await Promise.all(
      matchingFiles.map(async (file) => {
        const fileResponse = await fetch(file['@microsoft.graph.downloadUrl'])
        const fileBlob = await fileResponse.blob()
        const fileBuffer = await fileBlob.arrayBuffer()
        return {
          name: file.name,
          blob: fileBlob,
          buffer: fileBuffer,
          size: file.size,
        }
      })
    )

    return filesList
  } catch (error) {
    throw error
  }
}

export const deleteFilesFromChecklistFolder = async (
  vertical,
  account,
  project,
  files,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    // Fetch siteId, driveId, and itemId
    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/KMS.Mantle`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const itemPath = '/PR Staging Area'
    const itemIdResponse = await client
      .api(`/drives/${driveId}/root:${itemPath}`)
      .get()
    const itemId = itemIdResponse.id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())
    // Find or create the review folder
    const VerticalfolderId = await findFolder(
      client,
      driveId,
      itemId,
      Verticalfolder
    )

    // Find or create the phase level child folder
    const AccountfolderId = await findFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )

    const ProjectfolderId = await findFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )
    const deleteFilePromises = files.map(async (file) => {
      const encodedfilename = encodeURIComponent(file)
      const fileId = await findFolder(
        client,
        driveId,
        ProjectfolderId,
        encodedfilename
      )
      if (fileId) {
        const deleteUrl = `/drives/${driveId}/items/${fileId}`
        const deleteResponse = await client.api(deleteUrl).delete()
        console.log(`File deleted from SharePoint: ${file}`)
      }
    })

    // Wait for all files to be deleted
    await Promise.all(deleteFilePromises)
  } catch (error) {
    throw error
  }
}

export const linksToFilesInSharePoint = async (
  // file,
  vertical,
  account,
  project,
  checklist,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    // Fetch siteId, driveId, and itemId
    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/KMS.Mantle`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const itemPath = '/PR Staging Area'
    const itemIdResponse = await client
      .api(`/drives/${driveId}/root:${itemPath}`)
      .get()
    const itemId = itemIdResponse.id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())
    // Find or create the review folder
    const VerticalfolderId = await findFolder(
      client,
      driveId,
      itemId,
      Verticalfolder
    )

    // Find or create the phase level child folder
    const AccountfolderId = await findFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )

    const ProjectfolderId = await findFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )

    // Perform the file link
    const ProjectfolderQuery = `/drives/${driveId}/items/${ProjectfolderId}/children`
    const ProjectfolderResponse = await client.api(ProjectfolderQuery).get()
    const filterFilenamesByWord = (files, wordToFind) =>
      files?.filter((file) =>
        file.name.toLowerCase().includes(wordToFind.toLowerCase())
      )

    // Filter the list of filenames
    const filteredFilenames = filterFilenamesByWord(
      ProjectfolderResponse?.value,
      checklist
    )
    const updatedFilesArray = filteredFilenames.map((obj) => {
      const updatedName = obj.name.replace(`_${checklist}`, '')
      return { ...obj, name: updatedName }
    })
    const linkstofiles = updatedFilesArray?.map((obj) => ({
      name: obj.name,
      link: obj.webUrl,
    }))
    return linkstofiles
  } catch (error) {
    throw error.message
  }
}

async function findOrCreateFolderinDocument(client, driveId, folderName) {
  const escapedFolderName = folderName.replace(/['",]/g, ' ')
  try {
    const existingFolderQuery = `/drives/${driveId}/root/children?filter=name eq '${escapedFolderName}'`
    const existingFolderResponse = await client.api(existingFolderQuery).get()
    if (existingFolderResponse.value.length > 0) {
      return existingFolderResponse.value[0].id
    } else {
      const decodedFolderName = decodeURIComponent(folderName)
      const escapedFolderName = decodedFolderName.replace(/['",]/g, ' ')
      const newFolderResponse = await client
        .api(`/drives/${driveId}/root/children`)
        .post({
          name: escapedFolderName,
          folder: {},
        })
      return newFolderResponse.id
    }
  } catch (error) {
    throw error
  }
}

export const uploadFileToFinalSharePoint = async (
  filesArray,
  vertical,
  account,
  project,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  const uploadedFileNames = []

  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    // Fetch siteId, driveId, and itemId
    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/ProjectReviewDocs`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())

    const VerticalfolderId = await findOrCreateFolderinDocument(
      client,
      driveId,
      Verticalfolder
    )

    const AccountfolderId = await findOrCreateFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )

    const ProjectfolderId = await findOrCreateFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )

    // Upload each file and capture the names of successfully uploaded files
    for (const file of filesArray) {
      try {
        const uploadedFileName = await uploadFileChunkstoFinalSP(
          client,
          driveId,
          ProjectfolderId,
          file.name,
          file,
          accessToken
        )
        uploadedFileNames.push(uploadedFileName)
      } catch (error) {
        // Handle upload error, if any
        console.error(`Error uploading ${file.name}: ${error.message}`)
      }
    }

    // Return the array of uploaded file names as a response
    return uploadedFileNames
  } catch (error) {
    throw error.message
  }
}
//  Upload to Sharepoint artifacts for new 'Other Artifacts' peice--------->

export const FileInMantleSharePoint = async (
  vertical,
  account,
  project,
  checklist,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/KMS.Mantle`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const itemPath = '/PR Staging Area'
    const itemIdResponse = await client
      .api(`/drives/${driveId}/root:${itemPath}`)
      .get()
    const itemId = itemIdResponse.id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())

    const VerticalfolderId = await findOrCreateFolder(
      client,
      driveId,
      itemId,
      Verticalfolder
    )

    const AccountfolderId = await findOrCreateFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )
    const ProjectfolderId = await findOrCreateFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )
    const existingfilesQuery = `/drives/${driveId}/items/${ProjectfolderId}/children`

    const existingfilesResponse = await client.api(existingfilesQuery).get()

    const matchingFiles = existingfilesResponse.value.filter((file) => {
      const checklistName = checklist
      const dynamicExtension = file.name.split('.').pop()
      return file.name.endsWith(`_${checklistName}.${dynamicExtension}`)
    })

    const filesList = await Promise.all(
      matchingFiles.map(async (file) => {
        const fileResponse = await fetch(file['@microsoft.graph.downloadUrl'])
        const fileBlob = await fileResponse.blob()
        const fileBuffer = await fileBlob.arrayBuffer()
        return {
          name: file.name,
          blob: fileBlob,
          buffer: fileBuffer,
          size: file.size,
        }
      })
    )

    return filesList
  } catch (error) {
    throw error
  }
}
export const uploadArtifactsToFinalSharePoint = async (
  file,
  vertical,
  account,
  project,
  checklist,
  accessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'

  try {
    const client = graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken)
      },
    })

    // Fetch siteId, driveId, and itemId
    const siteIdResponse = await client
      .api(`/sites/${hostname}:/sites/ProjectReviewDocs`)
      .get()
    const siteId = siteIdResponse.id
    const driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
    const driveId = driveIdResponse.value[0].id
    const Verticalfolder = encodeURIComponent(vertical?.trim())
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())

    const originalFileName = file.name // Replace with your original file name

    const fileExtension = originalFileName.split('.').pop()
    const newFileName = `${originalFileName.replace(
      `.${fileExtension}`,
      `_${checklist}.${fileExtension}`
    )}`

    const VerticalfolderId = await findOrCreateFolderinDocument(
      client,
      driveId,
      Verticalfolder
    )

    const AccountfolderId = await findOrCreateFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )

    const ProjectfolderId = await findOrCreateFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )

    await uploadFileChunks(
      client,
      driveId,
      ProjectfolderId,
      newFileName,
      file,
      accessToken
    )

    return 'Final Uploaded Successfully!'
  } catch (error) {
    throw error.message
  }
}

export const linksToArtifactsInSharePoint = async (
  role,
  status,
  filename,
  vertical,
  account,
  project,
  checklist,
  accessToken,
  dummyaccessToken
) => {
  const hostname = 'tasolutions.sharepoint.com'
  try {
    let client
    // const client = graph.Client.init({
    //   authProvider: (done) => {
    //     done(null, accessToken)
    //   },
    // })

    // Fetch siteId, driveId, and itemId
    let siteIdResponse
    let siteId
    let driveIdResponse
    let driveId
    let itemPath
    let itemIdResponse
    let itemId
    let Verticalfolder
    let VerticalfolderId
    const Accountfolder = encodeURIComponent(account?.trim())
    const Projectfolder = encodeURIComponent(project?.trim())
    if (
      (role == 'Reviewer' || role == 'Admin') &&
      status.toLowerCase() === 'pending'.toLowerCase()
    ) {
      client = graph.Client.init({
        authProvider: (done) => {
          done(null, accessToken)
        },
      })
      siteIdResponse = await client
        .api(`/sites/${hostname}:/sites/KMS.Mantle`)
        .get()
      siteId = siteIdResponse.id
      driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
      driveId = driveIdResponse.value[0].id
      itemPath = '/PR Staging Area'
      itemIdResponse = await client
        .api(`/drives/${driveId}/root:${itemPath}`)
        .get()
      itemId = itemIdResponse.id
      Verticalfolder = encodeURIComponent(vertical?.trim())
      // Find or create the review folder
      VerticalfolderId = await findFolder(
        client,
        driveId,
        itemId,
        Verticalfolder
      )
    } else {
      client = graph.Client.init({
        authProvider: (done) => {
          done(null, dummyaccessToken)
        },
      })
      siteIdResponse = await client
        .api(`/sites/${hostname}:/sites/ProjectReviewDocs`)
        .get()
      siteId = siteIdResponse.id
      driveIdResponse = await client.api(`/sites/${siteId}/drives`).get()
      driveId = driveIdResponse.value[0].id
      Verticalfolder = encodeURIComponent(vertical?.trim())

      VerticalfolderId = await findOrCreateFolderinDocument(
        client,
        driveId,
        Verticalfolder
      )
    }

    const AccountfolderId = await findFolder(
      client,
      driveId,
      VerticalfolderId,
      Accountfolder
    )

    const ProjectfolderId = await findFolder(
      client,
      driveId,
      AccountfolderId,
      Projectfolder
    )

    // Perform the file link
    const ProjectfolderQuery = `/drives/${driveId}/items/${ProjectfolderId}/children`
    const ProjectfolderResponse = await client.api(ProjectfolderQuery).get()
    const originalFileName = filename

    const fileExtension = originalFileName.split('.').pop()
    const newFileName = `${originalFileName.replace(
      `.${fileExtension}`,
      `_${checklist}.${fileExtension}`
    )}`
    const filterFilenamesByWord = (files, wordToFind) =>
      files?.filter((file) =>
        file.name.toLowerCase().includes(wordToFind.toLowerCase())
      )

    // Filter the list of filenames
    const filteredFilenames = filterFilenamesByWord(
      ProjectfolderResponse?.value,
      newFileName
    )
    const updatedFilesArray = filteredFilenames.map((obj) => {
      const updatedName = obj.name.replace(`_${checklist}`, '')
      return { ...obj, name: updatedName }
    })
    const linkstofiles = updatedFilesArray?.map((obj) => ({
      name: obj.name,
      link: obj.webUrl,
    }))
    return linkstofiles
  } catch (error) {
    throw error.message
  }
}
